<template>
  <div class="settings">
    <div class="nav" v-if="$windowWidth < 992">
      <BackSvg
          class="nav__back"
          @click.native="back"/>
      <p class="nav__title" v-if="$route.meta.title != 'Настройки'">{{$route.meta.title}}</p>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import BackSvg from "../../../assets/vue-icons/Settings/BackSvg";
export default {
  name: "SettingsMainView",
  components: {BackSvg},
  methods:{
    back(){
      if (this.$route.meta.title === 'Настройки'){
        this.$router.push({name : 'ParentDashboard'})
      } else {
        this.$router.push({name : 'SettingsMainListView'})
      }
    },
  },
  created() {
    this.$store.commit("SET_COLOR_TYPE",Math.floor(Math.random() * 4) + 2)
  },
}
</script>

<style scoped lang="scss">
.nav{
  margin-top: 16px;
  height: 26px;
  display: flex;
  position: relative;


  &__back{
    margin-left: 10px;
  }

  &__title{
    font-family: Roboto, "sans-serif";
    font-weight: 500;
    font-size: 16px;
    color: #333942;
    text-align: left;
    line-height: 19px;
    position: absolute;
    left: 50%; /* position the left edge of the element at the middle of the parent */

    transform: translate(-50%, 0%);     }
}


</style>
