<template>
  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="icons/стрелки/назад" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <polyline id="Fill-196" fill="#333942" points="26 11.375 6.22375 11.375 15.3075 2.29125 13 0 0 13 13 26 15.29125 23.70875 6.22375 14.625 26 14.625 26 11.375"></polyline>
    </g>
  </svg>
</template>

<script>
export default {
  name: "BackSvg"
}
</script>

<style scoped >
  svg{
    cursor: pointer;
  }
</style>
